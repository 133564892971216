
import "cropperjs/dist/cropper.css";
import { ContentLoader } from "vue-content-loader";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import { getAdd, getView, enviarNovaSenha } from "@/services/UsuarioService";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useSelectedUsuarioStore } from "@/pinia/crudUsuario/useUsuarioStore"
import UltilChanges from '@/composables/UtilChanges'
import { UserStore } from "@/pinia/user/UserStore";

export default defineComponent({
  name: "adicionarUsuario",

  components: {
    ContentLoader,
  },

  setup(_props, { emit }) {
    const loadingButton = ref(false)
    const userStore = UserStore();
    const isAdmin = userStore.getUser?.codNivel == -1
    const usuarioRaiz = ref(false)
    const formRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const usuarioStore = useSelectedUsuarioStore();
    const codUsuario = usuarioStore.getSelectedUsuario;
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const codNivel: any = ref([]);
    const loadingGenerateLink = ref(false)
    const blockEmailSend = ref(false)
    const countdown = ref(180)
    let loading = ref(false);
    let countdownInterval: ReturnType<typeof setInterval> | null = null;

    let formUsuario: any = reactive({
      email: "",
      nome: "",
      senha: "",
      codNivel: "",
      programaAgenda: 1,
      cpf: "",
      telefone: "",
      situacao: 1,
      atualizaLancamentoConcessionaria: 0
    });

    const formattedTime = computed(() => {
      const minutes = Math.floor(countdown.value / 60);
      const seconds = countdown.value % 60;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    });

    const programarQuadro = [
      {
        text: 'Não',
        value: 0
      },
      {
        text: 'Sim',
        value: 1
      }
    ]

    const { 
      validateEmail,
      validateCPF,
    } = UltilChanges()

    const validatePassword = (rule: any, formUsuario: any, callback: any) => {
      let password = formUsuario;

      if (password.length > 20) {
        return callback(new Error("limite de 20 caracteres"));
      } else if (password.length <= 0) {
        return callback(new Error("Digite uma senha válida"));
      }
      return true;
    };

    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const dadosUsuarioespecifico = ref({});
    const buscarcodNivelusuario = [
      {
        text: "Administrador",
        value: -1,
      },
      {
        text: distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda",
        value: 1,
      },
      {
        text: "Consultor",
        value: 2,
      },
      {
        text: "Orçamentista",
        value: 3,
      },
      {
        text: "Controlador de quadro",
        value: 4,
      },
      {
        text: "Visualizador de quadro",
        value: 5,
      },
    ];

    const rules = ref({
      nome: [
        {
          required: true,
          message: "Digite um nome válido",
          trigger: "change",
        },
      ],
      cpf: [
        {
          required: true,
          validator: validateCPF,
          trigger: "change"
        }
      ],
      email: [
        {
          required: true,
          validator: validateEmail,
          trigger: "change",
        },
      ],
      telefone: [
        {
          required: true,
          message: "Telefone obrigatório",
          trigger: "change"
        }
      ],
      codNivel: [
        {
          required: true,
          message: "Selecione um nível válido",
          trigger: "change",
        },
      ],
      senha: [
        {
          required: true,
          validator: validatePassword,
          trigger: "change",
        },
      ],
      programaAgenda: [
        {
          required: true,
          message: "Selecione uma opção",
          trigger: "change",
        }
      ]
    });

    function lidarUsuarioRaiz() {
      usuarioRaiz.value = false
      const codNivel = userStore.getUser?.codNivel
      if(codNivel === -1)
        usuarioRaiz.value = true
    }

    onMounted(async () => {
      lidarUsuarioRaiz()
      if (codUsuario) {
        getUsuario(codUsuario);
      }
    });

    async function getUsuario(codUsuario) {
      loading.value = true;
      let response = await getView(codUsuario);
      formUsuario.email = response.email;
      formUsuario.senha = response.senha;
      formUsuario.nome = response.nome;
      formUsuario.codNivel = response.codNivel;
      formUsuario.codUsuario = response.codUsuario;
      formUsuario.programaAgenda = response.programaAgenda;
      formUsuario.cpf = response.cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') || '';
      formUsuario.telefone = response.telefone?.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3') || '';
      formUsuario.situacao = response.situacao;
      formUsuario.atualizaLancamentoConcessionaria = response.atualizaLancamentoConcessionaria
      loading.value = false;
    }

    const submit = () => {
      if (!formRef.value) return;
      formRef.value.validate((valid) => {
        if (valid) {
          const postData = {
            codUsuario: formUsuario.codUsuario,
            email: formUsuario.email,
            nome: formUsuario.nome,
            codNivel: formUsuario.codNivel,
            programaAgenda: formUsuario.programaAgenda,
            cpf: formUsuario.cpf.replace(/\D/g, ''),
            telefone: formUsuario.telefone.replace(/\D/g, ''),
            situacao: formUsuario.situacao,
            atualizaLancamentoConcessionaria: formUsuario.atualizaLancamentoConcessionaria
          };

          if(!usuarioRaiz.value)
            delete postData.atualizaLancamentoConcessionaria

          if(!codUsuario){
            Swal.fire({
              title: "E-mail para Definir senha de acesso?",
              html: `Um e-mail será enviado para <strong>${formUsuario.email}</strong>, o endereço cadastrado, contendo as informações necessárias para criar sua senha e realizar o primeiro acesso.`,
              confirmButtonText: "Confirmar",
            }).then((result) => {
              if (result.isConfirmed) {
                updateOrCreated(postData)                
              } else if (result.isDenied) {
              }
            });
          }else{
            updateOrCreated(postData)         
          }
        }
      });
    };
  
    async function updateOrCreated(postData) {
      loadingButton.value = true
     
      await getAdd(postData).then((response) => {        
        
        if(!response.error){
          showTimeAlert(response.message);
          goTo('/cadastros/usuario/list')  
          emit("addTask", postData); 
        }else{
          showTimeAlert(response.message,'error');
        }       
      }).catch(e => {
        showTimeAlert(e.data?.message ?? 'Não foi possível completar a solicitação', "error");
      }).finally(() => {
        loadingButton.value = false;        
      });
    }

    async function generateLinkNewPassword(){

      if(codUsuario){
        Swal.fire({
          title: "Desejar continuar?",
          text: "Ao gerar uma nova senha, será enviado para o e-mail cadastrado um link de acesso para redefinição de senha. O e-mail pode demorar até 3 minutos para chegar; por favor, aguarde.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, enviar link!",
          reverseButtons: true
        }).then( async (result) => {
          if (result.isConfirmed) {
            const payload = {
              codUsuario: Number(codUsuario)
            }

            loadingGenerateLink.value = true
            
            await enviarNovaSenha(payload).then((response) => {
              showTimeAlert(response.message);
              timeButton()
              blockEmailSend.value = true
            }).catch((e) => {
              showTimeAlert(e.data?.message ?? 'Não foi possível completar a solicitação', 'error')
              blockEmailSend.value = false
              if(countdownInterval){
                clearInterval(countdownInterval)
              }
            }).finally(() => {              
              loadingGenerateLink.value = false
            })
          }
        });
      }      
    }

    function goTo(url) {
      router.push(url);
    }

    const timeButton = () => {
      countdownInterval = setInterval(() => {
        countdown.value -= 1;
        if (countdown.value <= 0) {
          if(countdownInterval){
            clearInterval(countdownInterval);
            blockEmailSend.value = false
            countdown.value = 180
          }        
        }
      }, 1000);
    }

    return {
      goTo,
      loading,
      formUsuario,
      rules,
      formRef,
      submit,
      codUsuario,
      codNivel,
      dadosUsuarioespecifico,
      validateEmail,
      loadingButton,
      programarQuadro,
      distribuidor,
      generateLinkNewPassword,
      loadingGenerateLink,
      usuarioRaiz,
      countdown,
      formattedTime,
      blockEmailSend,
      buscarcodNivelusuario,
      isAdmin
    };
  },
});
